export { default as QuestionIcon } from "./svg/questionIcon";
export { default as ShrinkIcon } from "./svg/shrinkIcon";
export { default as ExpandIcon } from "./svg/expandIcon";
export { default as AboutUsIcon } from "./svg/aboutUs";
export { default as NoIcon } from "./svg/noIcon";
export { default as Settings } from "./svg/settingsIcon";
export { default as Extensibility } from "./svg/extensibilityIcon";
export { default as Publications } from "./svg/publicationsIcon";
export { default as OrgChartIcon } from "./svg/orgChartIcon";
export { default as TalentAcquisitionIcon } from "./svg/talentAcquisitionIcon";
export { default as PalleteIcon } from "./svg/paletteIcon";
export { default as DirectoyButton } from "./svg/directoryButton";
export { default as OpenLock } from "./svg/openLock";
export { default as PersonalSheet } from "./svg/personalSheet";
export { default as PowerOffButton } from "./svg/powerOffButton";
export { default as DocumentIcon } from "./svg/documentIcon";
export { default as CoinsIcon } from "./svg/coinsIcon";
export { default as ShieldAlert } from "./svg/shieldAlert";
export { default as PersonalIcon } from "./svg/personalIcon";
export { default as PerformanceManagementIcon } from "./svg/performanceManagementIcon";
export { default as RequestsIcon } from "./svg/requestsIcon";
export { default as TimeManagementIcon } from "./svg/timeManagementIcon";
export { default as EngagementIcon } from "./svg/engagementIcon";
export { default as ManagementIcon } from "./svg/ManagementIcon";
export { default as TwitterIcon } from "./svg/twitter-logo";
export { default as FacebookIcon } from "./svg/facebook-logo";
export { default as LinkedinIcon } from "./svg/linkedin-logo";
export { default as InstagramLogo } from "./svg/instagram-logo";
export { default as NineBoxSettingsIcon } from "./svg/nineBoxSettingsIcon";
export { default as NineBoxClassificationIcon } from "./svg/nineBoxClassificationIcon";
export { default as BrushIcon } from '@material-ui/icons/Brush';
export { default as BorderColorIcon } from "@material-ui/icons/BorderColor";
export { default as FormatColorFillIcon } from "@material-ui/icons/FormatColorFill";
export { default as LaunchIcon } from "@material-ui/icons/Launch";
export { default as FlightTakeoffIcon } from "@material-ui/icons/FlightTakeoff";
export { default as DirectionsRunIcon } from "@material-ui/icons/DirectionsRun";
export { default as CreditCardIcon } from "@material-ui/icons/CreditCard";
export { default as LocalAtmIcon } from "@material-ui/icons/LocalAtm";
export { default as DownloadIcon } from "@material-ui/icons/CloudDownload";
export { default as LinkIcon } from "@material-ui/icons/Link";
export { default as RotateLeftIcon } from "@material-ui/icons/RotateLeft";
export { default as RotateRightIcon } from "@material-ui/icons/RotateRight";
export { default as CloseIcon } from "@material-ui/icons/Close";
export { default as RoomIcon } from "@material-ui/icons/Room";
export { default as EventAvailableIcon } from '@material-ui/icons/EventAvailable';
export { default as PeopleIcon } from "@material-ui/icons/People";
export { default as InfoIcon } from "@material-ui/icons/Info";
export { default as DislikeIcon } from "@material-ui/icons/ThumbDown";
export { default as LikeIcon } from "@material-ui/icons/ThumbUp";
export { default as CheckIcon } from "@material-ui/icons/CheckCircle";
export { default as WarningIcon } from "@material-ui/icons/Warning";
export { default as PersonIcon } from "@material-ui/icons/Person";
export { default as LockIcon } from "@material-ui/icons/Lock";
export { default as WorkIcon } from "@material-ui/icons/Work";
export { default as GetAppIcon } from "@material-ui/icons/GetApp";
export { default as PublishIcon } from "@material-ui/icons/Publish";
export { default as EditIcon } from "@material-ui/icons/Edit";
export { default as DeleteForeverIcon } from "@material-ui/icons/DeleteForever";
export { default as AddIcon } from "@material-ui/icons/Add";
export { default as RemoveIcon } from '@material-ui/icons/Remove';
export { default as PencilIcon } from "./svg/pencil"
export { default as PortraitIcon } from "@material-ui/icons/Portrait";
export { default as DeleteIcon } from "@material-ui/icons/Delete";
export { default as SaveIcon } from "@material-ui/icons/Save";
export { default as PhoneIcon } from "@material-ui/icons/Phone";
export { default as EnvelopeIcon } from "@material-ui/icons/Mail";
export { default as LocationIcon } from "@material-ui/icons/LocationOn";
export { default as SmartphoneIcon } from "@material-ui/icons/Smartphone";
export { default as CloudDownloadIcon } from "@material-ui/icons/CloudDownload";
export { default as ExpandLess } from "@material-ui/icons/ExpandLess";
export { default as ExpandMore } from "@material-ui/icons/ExpandMore";
export { default as PieChartIcon } from "@material-ui/icons/PieChart";
export { default as ChatIcon } from "@material-ui/icons/Chat";
export { default as SendIcon } from "@material-ui/icons/Send";
export { default as ApartmentIcon } from "@material-ui/icons/Apartment";
export { default as PlayCircleOutlineIcon } from "@material-ui/icons/PlayCircleOutline";
export { default as ErrorIcon } from "@material-ui/icons/Error";
export { default as ArrowLeft } from "@material-ui/icons/KeyboardArrowLeft";
export { default as ArrowRight } from "@material-ui/icons/KeyboardArrowRight";
export { default as MenuIcon } from "@material-ui/icons/Menu";
export { default as ExpandMoreIcon } from "@material-ui/icons/ExpandMore";
export { default as MoreIcon } from "@material-ui/icons/More";
export { default as TrendingUpIcon } from "@material-ui/icons/TrendingUp";
export { default as HighlightPositionsAvailableIcon } from "./svg/highlightPositionsAvailableIcon";
export { default as HighlightCompetgenceGroupIcon } from "./svg/highlightCompetgenceGroupIcon";
export { default as HighlightEvaluationGroupIcon } from "./svg/highlightEvaluationGroupIcon";
export { default as MailIcon } from "@material-ui/icons/Mail";
export { default as AttachFileIcon } from '@material-ui/icons/AttachFile';
export { default as KeyboardBackspaceIcon } from '@material-ui/icons/KeyboardBackspace';
export { default as TypesOfScoreIcon } from "./svg/typesOfScoreIcon";
export { default as RatingScalesIcon } from "./svg/ratingScalesIcon";
export { default as ThemeIcon } from "@material-ui/icons/Brightness4";
export { default as TranslateIcon } from '@material-ui/icons/Translate';
export { default as PersonAdd } from '@material-ui/icons/PersonAdd';
export { default as TextIcon } from '@material-ui/icons/Subject';
export { default as WorkOffIcon } from '@material-ui/icons/WorkOff';
export { default as VisibilityIcon } from '@material-ui/icons/Visibility';
export { default as ArchiveIcon } from '@material-ui/icons/Archive';
export { default as UnarchiveIcon } from '@material-ui/icons/Unarchive';
export { default as PlaylistAddCheckIcon } from '@material-ui/icons/PlaylistAddCheck';
export { default as FormatListBulleted } from '@material-ui/icons/FormatListBulleted';
export { default as CheckBoxIcon } from '@material-ui/icons/CheckBox';
export { default as CheckBoxOutlineBlankIcon } from '@material-ui/icons/CheckBoxOutlineBlank';
export { default as LabelIcon } from '@material-ui/icons/Label';
export { default as CheckBoxOutlinedIcon } from '@material-ui/icons/CheckBoxOutlined';
export { default as AccessTimeIcon } from '@material-ui/icons/AccessTime';
export { default as NotificationsActiveIcon } from '@material-ui/icons/NotificationsActive';
export { default as ImageIcon } from '@material-ui/icons/Image';
export { default as WatchLaterIcon } from '@material-ui/icons/WatchLater';
export { default as ShareIcon } from '@material-ui/icons/Share';
export { default as ArrowDropDownIcon } from '@material-ui/icons/ArrowDropDown';
export { default as ArrowDropUpIcon } from '@material-ui/icons/ArrowDropUp';
export { default as GroupsIcon } from "./svg/groupsIcon";
export { default as SearchIcon } from '@material-ui/icons/Search';
export { default as NoGroupsSvg } from "./svg/noGroupsSvg";
export { default as CalculationIcon } from "./svg/calculationIcon";
export { default as InsertDriveFileIcon } from "./svg/insertDriveFile";
export { default as DoneIcon } from '@material-ui/icons/Done';
export { default as FileCopyIcon } from '@material-ui/icons/FileCopy';
export { default as FunctionsIcon } from "./svg/functionsIcon";
export { default as PrintIcon } from '@material-ui/icons/Print';
export { default as MapIcon } from '@material-ui/icons/Map';
export { default as ExcelIcon } from "./svg/excelIcon";
export { default as FilterIcon } from "./svg/filterIcon";
export { default as FilterButtonIcon } from "./svg/filterButtonIcon";
export { default as ImagePlaceholder } from "./svg/imagePlaceholder";
export { default as PublicationIcon } from "./svg/publicationIcon";
export { default as PublicationIconImportantNews } from "./svg/publicationIconImportantNews";
export { default as PublicationIconLinks } from "./svg/publicationIconLinks";
export { default as PublicationIconDownloads } from "./svg/publicationIconDownloads";
export { default as PublicationIconNumbers } from "./svg/publicationIconNumbers";
export { default as WorldIcon } from '@material-ui/icons/Public';
export { default as CollabIcon } from "./svg/collabIcon";
export { default as ManagerIcon } from "./svg/managerIcon";
export { default as EmployerIcon } from "./svg/employerIcon";
export { default as FolderIcon } from "./svg/folderIcon";
export { default as AudiotrackIcon } from '@material-ui/icons/Audiotrack';
export { default as WomanIcon } from "./svg/womanIcon";
export { default as ManIcon } from "./svg/manIcon";
export { default as ConceptsIcon } from "./svg/conceptsIcon";
export { default as CalculationFunctionIcon } from "./svg/calculationFunctionIcon";
export { default as ConceptGroupsIcon } from "./svg/conceptGroupsIcon";
export { default as AccumIcon } from "./svg/accumIcon";
export { default as CategoryIcon } from "./svg/categoryIcon";
export { default as SpecialtyIcon } from "./svg/specialtyIcon";
export { default as PayrollTypeIcon } from "./svg/payrollTypeIcon";
export { default as DiseasesIcon } from "./svg/diseasesIcon";
export { default as ExamsIcon } from "./svg/examsIcon";
export { default as AllergiesIcon } from "./svg/allergiesIcon";
export { default as TypesOfPublicationFormatsIcon } from "./svg/typesOfPublicationFormatsIcon";
export { default as TypesOfOnboardingActionsIcon } from "./svg/typesOfOnboardingActionsIcon";
export { default as TypesOfPublicationChannelsIcon } from "./svg/typesOfPublicationChannelsIcon";
export { default as ClassificationApplicantsTypesIcon } from "./svg/classificationApplicantsTypesIcon";
export { default as CalculationReferenceIcon } from "./svg/calculationReferenceIcon";
export { default as EntryIcon } from "./svg/entryIcon";
export { default as ExitIcon } from "./svg/exitIcon";
export { default as Folder } from '@material-ui/icons/Folder';
export { default as VpnKeyIcon } from '@material-ui/icons/VpnKey';
export { default as HomePublicationsIcon } from "./svg/homePublicationsIcon";
export { default as DocumentPublicationsIcon } from "./svg/documentPublicationsIcon";
export { default as ManagementCenterIcon } from "./svg/managementCenterIcon";
export { default as AdvancesIcon } from "./svg/advancesIcon";
export { default as PermissionsIcon } from "./svg/permissionsIcon";
export { default as ContractTypesIcon } from "./svg/contractTypesIcon";
export { default as FamilyIcon } from "./svg/familyIcon";
export { default as FunctionaryTypeIcon } from "./svg/functionaryTypeIcon";
export { default as FunctionTypeIcon } from "./svg/functionTypeIcon";
export { default as WorkplacesIcon } from "./svg/workplacesIcon";
export { default as WorkAndTasksIcon } from "./svg/workAndTasksIcon";
export { default as DivisionsIcon } from "./svg/divisionsIcon";
export { default as VacationsIcon } from "./svg/vacationsIcon";
export { default as UnionIcon } from "./svg/unionIcon";
export { default as PositionEditorIcon } from "./svg/positionEditorIcon";
export { default as InstitutionsIcon } from "./svg/institutionsIcon";
export { default as HealthIcon } from "./svg/healthIcon";
export { default as ContractorIcon } from "./svg/contractorIcon";
export { default as AbsenceIcon } from "./svg/absenceIcon";
export { default as PermissionIcon } from "./svg/permissionIcon";
export { default as LicenceIcon } from "./svg/licenceIcon";
export { default as PositionsClassificationIcon } from "./svg/positionsClassificationIcon";
export { default as SentimentGood } from "./svg/sentimentGood";
export { default as SentimentBad } from "./svg/sentimentBad";
export { default as SentimentNeutral } from "./svg/sentimentNeutral";
export { default as QueryDefinitionIcon } from "./svg/queryDefinitionIcon";
export { default as GeoanalysisDefinitionIcon } from "./svg/geoanalysisDefinitionIcon";
export { default as KpiDefinitionIcon } from "./svg/kpiDefinitionIcon";
export { default as GeneralValuesIcon } from "./svg/generalValuesIcon";
export { default as ConceptStructureIcon } from "./svg/conceptStructureIcon";
export { default as AlertsSystemIcon } from "./svg/alertsSystemIcon";
export { default as AlertsUserIcon } from "./svg/alertsUserIcon";
export { default as ConceptsSetIcon } from "./svg/conceptsSetIcon";
export { default as ThirdPartyIcon } from "./svg/thirdPartyIcon";
export { default as ThirdPartyClassificationIcon } from "./svg/thirdPartyClassificationIcon";
export { default as LaborUnionsIcon } from "./svg/laborUnionsIcon";
export { default as PaymentTypesIcon } from "./svg/paymentTypesIcon";
export { default as DocumentTemplatesIcon } from "./svg/documentTemplatesIcon";
export { default as AssetsAndDiscountsCreationIcon } from "./svg/assetsAndDiscountsCreationIcon";
export { default as AssetsAndDiscountsEntryInInstallmentsIcon } from "./svg/assetsAndDiscountsEntryInInstallmentsIcon";
export { default as AssetsAndDiscountsEntryPerSocietyIcon } from "./svg/assetsAndDiscountsEntryPerSocietyIcon";
export { default as AssetsAndDiscountsSummaryIcon } from "./svg/assetsAndDiscountsSummaryIcon";
export { default as AssetsAndDiscountsEntryPerConceptSetIcon } from "./svg/assetsAndDiscountsEntryPerConceptSetIcon";
export { default as AssetsAndDiscountsEntryPerConceptIcon } from "./svg/assetsAndDiscountsEntryPerConceptIcon";
export { default as DigitalWarehouseFileTypeIcon } from "./svg/digitalWarehouseFileTypeIcon";
export { default as HolidayTableIcon } from "./svg/holidayTableIcon";
export { default as TypesOfBenefitsIcon } from "./svg/typesOfBenefitsIcon";
export { default as BenefitsForPlansIcon } from "./svg/benefitsForPlansIcon";
export { default as OrganizationalBenefitsIcon } from "./svg/organizationalBenefitsIcon";
export { default as ExtraHoursTableIcon } from "./svg/extraHoursTableIcon";
export { default as TotemsIcon } from "./svg/totemsIcon";
export { default as FestivitiesTableIcon } from "./svg/festivitiesTableIcon";
export { default as LanguagesIcon } from "./svg/languagesIcon";
export { default as SchoolingIcon } from "./svg/schoolingIcon";
export { default as StudiesIcon } from "./svg/studiesIcon";
export { default as SpecializationsIcon } from "./svg/specializationsIcon";
export { default as HealthInstitutionIcon } from "./svg/healthInstitutionIcon";
export { default as RetirementIcon } from "./svg/retirementIcon";
export { default as BasicFolderIcon } from "@material-ui/icons/Folder";
export { default as WarehouseIcon } from "./svg/warehouseIcon";
export { default as TaxTableIcon } from "./svg/taxTableIcon";
export { default as FamilyAsignationsIcon } from "./svg/familyAsignationsIcon";
export { default as RatingsIcon } from "./svg/ratingsIcon";
export { default as DataCaptureIcon } from "./svg/dataCaptureIcon";
export { default as MassEntryIcon } from "./svg/massEntryIcon";
export { default as ImportIcon } from "./svg/importIcon";
export { default as CalculationsManagementIcon } from "./svg/calculationsManagementIcon";
export { default as SpecialProcessDefinitionIcon } from "./svg/specialProcessDefinitionIcon";
export { default as AccountTreeIcon } from '@material-ui/icons/AccountTree';
export { default as AssessmentIcon } from '@material-ui/icons/Assessment';
export { default as TreeOrgChartIcon } from "./svg/treeOrgChartIcon";
export { default as FilterNoneIcon } from "@material-ui/icons/FilterNone";
export { default as GeographicalStructure } from "./svg/geographicalStructureIcon";
export { default as SchedulesProgrammingIcon } from "./svg/schedulesProgrammingIcon";
export { default as ScheduleDefinitionIcon } from "./svg/scheduleDefinitionIcon";
export { default as TurnDefinitionIcon } from "./svg/turnDefinitionIcon";
export { default as AlertsTemplateIcon } from "./svg/alertsTemplateIcon";
export { default as CalendarDefinitionIcon } from "./svg/calendarDefinitionIcon";
export { default as TodayIcon } from "@material-ui/icons/Today";
export { default as ConsentTypesIcon } from "./svg/consentTypesIcon";
export { default as BenefitsCenterIcon } from "./svg/benefitsCenterIcon";
export { default as ProjectsIcon } from "./svg/projectsIcon";
export { default as ConsentPublicationIcon } from "./svg/consentPublicationIcon";
export { default as FamilyGroupIcon } from "./svg/familyGroupIcon";
export { default as GroupIcon } from "./svg/groupIcon";
export { default as DistributionCostIcon } from "./svg/distributionCostIcon";
export { default as IndividualPayrollIcon } from "./svg/individualPayrollIcon";
export { default as SettingsWheel } from '@material-ui/icons/Settings';
export { default as CatalogsIcon } from "./svg/catalogsIcon";
export { default as RelatedTablesDefinitionIcon } from "./svg/relatedTablesDefinitionIcon";
export { default as PerformanceFormUpwardIcon } from "./svg/performanceFormUpwardIcon";
export { default as PerformanceFormDownwardIcon } from "./svg/performanceFormDownwardIcon";
export { default as PerformanceFormSelfEvaluationIcon } from "./svg/performanceFormSelfEvaluationIcon";
export { default as PerformanceFormPeerEvaluationIcon } from "./svg/performanceFormPeerEvaluationIcon";
export { default as OnboardingProfilesIcon } from "./svg/onboardingProfilesIcon";
export { default as UserTableDefinitionIcon } from "./svg/userTableDefinitionIcon";
export { default as SystemTableDefinitionIcon } from "./svg/systemTableDefinitionIcon";
export { default as BronzeMedalIcon } from "./svg/bronzeMedalIcon";
export { default as SilverMedalIcon } from "./svg/silverMedalIcon";
export { default as GoldMedalIcon } from "./svg/goldMedalIcon";
export { default as BronzeShieldIcon } from "./svg/bronzeShieldIcon";
export { default as SilverShieldIcon } from "./svg/silverShieldIcon";
export { default as GoldShieldIcon } from "./svg/goldShieldIcon";
export { default as RecognitionPlansIcon } from "./svg/recognitionPlansIcon";
export { default as PerformanceFormsIcon } from "./svg/performanceFormsIcon";
export { default as FeedbackPurposeIcon } from "./svg/feedbackPurposeIcon";
export { default as GroupClassificationIcon } from "./svg/groupClassificationIcon";
export { default as ReasonForPointAwardingIcon } from "./svg/reasonForPointAwardingIcon";
export { default as MedalTableIcon } from "./svg/medalTableIcon";
export { default as RecognitionAnalysisIcon } from "./svg/recognitionAnalysisIcon";
export { default as ActionTypesIcon } from "./svg/actionTypesIcon";
export { default as KpiComparisonIcon } from "./svg/kpiComparisonIcon";
export { default as UserMenusIcon } from "./svg/userMenusIcon";
export { default as TypesOfObjectivesIcon } from "./svg/typesOfObjectivesIcon";
export { default as PositionGroupsForEvaluationsIcon } from "./svg/positionGroupsForEvaluationsIcon";
export { default as PeriodsForObjectivesIcon } from "./svg/periodsForObjectivesIcon";
export { default as TypesOfSkillsIcon } from "./svg/typesOfSkillsIcon";
export { default as PerformanceAnalyticsEvaluationsIcon } from "./svg/performanceAnalyticsEvaluationsIcon";
export { default as PerformanceAnalyticsTalentIcon } from "./svg/performanceAnalyticsTalentIcon";
export { default as CommunicationClassificationIcon } from "./svg/communicationClassificationIcon";
export { default as IcariusSmartIcon } from "./svg/icariusSmartIcon";
export { default as ExcelBigIcon } from "./svg/excelBigIcon";
export { default as OnboardingMaterialsIcon } from "./svg/onboardingMaterialsIcon";
export { default as AssistanceCollaboratorIcon } from "./svg/assistanceCollaboratorIcon";
export { default as AssistanceEmployerIcon } from "./svg/assistanceEmployerIcon";
export { default as OnboardingProcessesIcon } from "./svg/onboardingProcessesIcon";
export { default as OnboardingTrackingIcon } from "./svg/onboardingTrackingIcon";
export { default as PerformanceAnalyticsHistoricIcon } from "./svg/performanceAnalyticsHistoricIcon";
export { default as EncryptorIcon } from "./svg/encryptorIcon";
export { default as AsssistanceAppIcon } from "./svg/asssistanceAppIcon";